@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap');

:root {
  --Primary: #181a20;
  --DarkPrimary: #060606;
  --LightPrimary: #1f222a;
  --Secondary: #ff8216;
  --LightSecondary: #fefdba;
  --Background: #0D0D0D;
  --Text: #ffffff;
  --White: #ffffff;
  --Gray: #b8b8b8;
  --LightGray: #dbdbdb;
  --DarkGray: #828282;
  --Red: #ea2e32;
  --DarkRed: #331f2c;
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  font-family: 'Cairo', sans-serif;
  background-color: var(--Primary);
}